
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "RatingInfoModal",
  props: {},
  data() {
    return {
      ratingList: [
        "The evaluation of the Session or the Mentorcast (1 to 10)",
        "The Mentor Values (1 to 10 for each value)",
        "The badge (3 badges to choose from)",
        "The comment"
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  }
});
