
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SelectBadgesModal",
  props: {
    profile: Object
  },
  data() {
    return {
      data: {
        badge_type: this.profile?.badge_type
      },
      allBadges: [
        {
          name: "Neutral",
          key: "neutral"
        },
        {
          name: "Male",
          key: "male"
        },
        {
          name: "Female",
          key: "female"
        }
      ],
      errors: {} as any,
      btnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      badges: "helper/getAllBadges"
    })
  },
  mounted() {
    this.$store.dispatch("helper/GET_ALL_BADGES");
  },
  methods: {
    onSubmit(): void {
      this.btnLoading = true;

      this.$store
        .dispatch("profile/UPDATE_BADGE_TYPE", this.data)
        .then(() => {
          this.btnLoading = false;
          this.updateStatistics();
          (this as any).$refs["badges-modal"].hide();
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    updateStatistics(): void {
      let type = "mentor/GET_STATISTICS";
      if (this.profile.type === "mentee") {
        type = "mentee/GET_STATISTICS";
      }
      this.$store.dispatch(type, this.profile.id);
    }
  }
});
