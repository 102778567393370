
import Vue from "vue";
import SelectBadgesModal from "@/components/Modals/SelectBadgesModal.vue";
import BadgesInfoModal from "@/components/Modals/BadgesInfoModal.vue";
import RatingInfoModal from "@/components/Modals/RatingInfoModal.vue";

export default Vue.extend({
  name: "MentoreeStatistics",
  components: {
    SelectBadgesModal,
    BadgesInfoModal,
    RatingInfoModal
  },
  props: {
    isProfileOwner: Boolean,
    profile: Object,
    menteeStatistics: Object
  },
  data() {
    return {
      maxMentoreeEvaluation: 10,
      completeBadgeValue: 1000,
      // Mentoree statistics items
      statistics: {
        attendedSession: {
          label: (this as any).$t(
            "statistic.mentoreeStatisticsSessionsAttended"
          ),
          icon: "sessions"
        },
        attendedMentorcasts: {
          label: (this as any).$t(
            "statistic.mentoreeStatisticsMentorcastsAttended"
          ),
          icon: "scene"
        },
        mentoredBy: {
          label: (this as any).$t("statistic.mentoreeStatisticsMentoredBy"),
          icon: "profile"
        },
        avgRating: {
          label: (this as any).$t("statistic.mentoreeStatisticsAverageRating"),
          icon: "star-rounded"
        },
        receivedRatings: {
          label: (this as any).$t(
            "statistic.mentoreeStatisticsRatingsReceived"
          ),
          icon: "rating"
        },
        mostGivenBadge: {
          label: (this as any).$t("statistic.mentoreeStatisticsMostGivenBadge"),
          icon: "badge",
          color: "primary"
        }
      }
    };
  }
});
